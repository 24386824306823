.input_wrapper {
  position: relative;
  width: 100%;
}

.input_wrapper input {
  display: block;
  width: 100%;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  border: 1px solid #eeeeee;
  padding: 1rem 2.5rem 1rem 1rem;
}

.places_list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #eeeeee;
  margin: 0 !important;
  text-align: left;
  background-color: #ffffff;
  box-shadow: 0 0 2px #b8b8b8;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0;
}

.places_list li {
  position: relative;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  z-index: 50;
  margin-left: 1.8rem;
}

.places_list li::before {
  position: absolute;
  content: "";
  top: 0;
  left: -30%;
  width: 130%;
  height: 100%;
  background-color: #eeeeee;
  display: none;
}

.places_list li:hover::before {
  display: block;
  z-index: -1;
}

.places_list li::marker {
  content: "\f3c5";
  font-family: "Font Awesome 6 Free";
  font-weight: 700;
  color: #df1c0e;
}

.places_list li:first-child {
  padding-top: 1rem;
}

.places_list li:last-child {
  padding-bottom: 1rem;
}

.clear_input_btn {
  position: absolute;
  top: 50% !important;
  right: 15px !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0rem !important;
  background-color: transparent !important;
  color: #df1c0e !important;
  outline: none !important;
  box-shadow: none !important;
}

.agents_wrapper {
  position: relative;
}

.agents_loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.agents_loader img {
  width: 70px;
  height: auto;
}
